import { deleteCookie, getCookie } from "../utils/gen-utils";
import { publicRoutes, ROUTES } from "./routes";

export const DOT_NET_COOKIE_NAME = ".AspNetCore.Identity.Bearer"

export const redirectIfNotLoggedIn = () => {
    const aspCookie = getCookie(DOT_NET_COOKIE_NAME);
    if (!aspCookie) {
        doFullLogout();
    }
}

export const doFullLogout = () => {
    // Clear cookies
    document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Clear local storage
    localStorage.clear();
    deleteCookie(DOT_NET_COOKIE_NAME)
    // Clear session storage
    sessionStorage.clear();

    console.log("redirecting in api-constants", window.location.pathname)
    if (!publicRoutes.includes(window.location.pathname)) {
        window.location.href = ROUTES.LANDING;
    }

}