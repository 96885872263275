import { ElementRef, forwardRef, JSX, useMemo } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from "@mui/material";

export type SelectItem = {
  label?: string;
  value?: string;
};

export type SelectProps = MuiSelectProps & {
  helperText?: string | JSX.Element;
  options: SelectItem[];
};

export const Select = forwardRef<ElementRef<typeof MuiSelect>, SelectProps>(
  ({ options, error, label, helperText, ...props }, ref) => {
    const labelId = useMemo(
      () => props.name + "-label-" + new Date().getTime(),
      [props.name]
    );

    return (
      <FormControl fullWidth error={error}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <MuiSelect ref={ref} {...props} labelId={labelId} label={label}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);
