import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../interfaces/store'
import { Modal, Box, Typography, Button } from '@mui/material'
import { logout } from '../../store/actions/userActions'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const timeout = 240_000
const promptBeforeIdle = 60_000

// Styles for Material UI modal
const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function InactivityHandler() {
    const [state, setState] = useState<string>('Active')
    const [remaining, setRemaining] = useState<number>(timeout)
    const [open, setOpen] = useState<boolean>(false)
    const dispatch = useDispatch();
    const my_user = useSelector((state: IRootState) => state.main.my_user);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const onIdle = () => {
        setState('Idle')
        setOpen(false)
        if (my_user?.id) {
            console.log("Logging out...");
            dispatch(logout({}, () => {
                navigate('/Signup');
            }));
        }
        else{
            activate()
        }
    }

    const onActive = () => {
        setState('Active')
        setOpen(false)
    }

    const onPrompt = () => {
        if (my_user.id){
            setState('Prompted')
            setOpen(true)
        }
        else{
            activate()
        }

    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
        activate()
    }

    const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
    const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

    return <Modal open={open} onClose={() => { }}>
        <Box sx={modalStyle}>
            <Typography variant="h6" component="h2">
                {t("You've been inactive")}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {t('Would you like to stay logged in?')}
                <p>{t('Logging out in')} {remaining} {t('seconds')}</p>
            </Typography>
            <Button onClick={handleStillHere} variant="contained" sx={{ mt: 2, mr: 1 }}>
                {t('yes')}
            </Button>
            <Button onClick={() => { }} variant="outlined" sx={{ mt: 2 }}>
                {t('no')}
            </Button>
        </Box>
    </Modal>;
}


{/* 
{timeTillPrompt > 0 && (
  <p>
    {timeTillPrompt} {seconds} until prompt
  </p>
)} */}