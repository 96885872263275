import React from 'react';
import { Grid, Card, CardContent, Typography, Button, useMediaQuery, Theme, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import CenteredContainer from '../../../components/layout/CenteredContainer';
import { MARGIN_LARGE } from '../../../shared/constants/layout-constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

const SignupLanding: React.FC = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <CenteredContainer>
      <Box
        style={{ marginTop: MARGIN_LARGE }}
        sx={{
          width: '95vw',
          [theme.breakpoints.up('md')]: {
            width: 1200,
          },
        }}
      >

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
            <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
              <CardContent style={{ flexGrow: 1 }}>
                <Typography variant="h6" gutterBottom>
                  {t('New Gold Plan Signup')}
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  {t('Are you signing up for the Gold Plan for the first time?')}
                </Typography>
              </CardContent>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size={isSmallScreen ? 'small' : 'large'}
                  onClick={() => {
                    navigate('/signup');
                  }}
                  style={{ margin: '16px', width: '50%' }}
                >
                  {t('getStarted')}
                </Button>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
            <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
              <CardContent style={{ flexGrow: 1 }}>
                <Typography variant="h6" gutterBottom>
                  {t('Current or Previous Gold Plan Members')}
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  {t('Wanting to RENEW or UPDATE your Gold Plan online? You will first need to create an online portal account. You will need your patient ID number and last name.')}
                </Typography>
              </CardContent>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size={isSmallScreen ? 'small' : 'large'}
                  onClick={() => console.log('Register clicked')}
                  style={{ margin: '16px', width: '50%' }}
                >
                  {t('register')}
                </Button>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
            <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
              <CardContent style={{ flexGrow: 1 }}>
                <Typography variant="h6" gutterBottom>
                  {t('Login to your Portal Account')}
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  {t('Login here if you have already created an online portal account.')}
                </Typography>
              </CardContent>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size={isSmallScreen ? 'small' : 'large'}
                  onClick={() => console.log('Sign In clicked')}
                  style={{ margin: '16px', width: '50%' }}
                >
                  {t('signIn')}
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>


      </Box>

    </CenteredContainer>
  );
};

export default SignupLanding;