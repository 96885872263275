
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { JSX } from 'react';

export interface IStandardModalAction {
    title: string
    callback: Function
}


interface IStandardModalProps {
    open: boolean
    onClose: Function
    title: string
    paragraphs?: string[]
    actions?: IStandardModalAction[]
    customActions?: any
    children?: JSX.Element
    maxWidth?: any
}

const StandardModal: React.FC<IStandardModalProps> = (props) => {

    const { children } = props
    return (
        <Dialog fullWidth maxWidth={props.maxWidth || 'sm'} onClose={() => props.onClose()} aria-labelledby="customized-dialog-title" open={props.open}>
            <MuiDialogTitle>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 3, marginTop: 10 }}>
                        <Typography variant="h6">{props.title}</Typography>
                    </div>
                    <div style={{ flex: 1, textAlign: 'right' }}>
                        <IconButton aria-label="close" onClick={() => props.onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </MuiDialogTitle>
            <DialogContent dividers>
                {children ? children : null}
                {props.paragraphs?.map((paragraph, index) => {
                    return (
                        <Typography key={index} gutterBottom>
                            {paragraph}
                        </Typography>
                    )
                })}
            </DialogContent>
            <DialogActions>
                {props.actions?.map((action, index) => {
                    return (
                        <div key={index}>
                            <Button autoFocus onClick={() => action.callback()} color="primary">
                                {action.title}
                            </Button>
                        </div>)
                })}
                {props.customActions?.map((action: any, index: any) => {
                    return (
                        <div key={index}>
                            {action}
                        </div>
                    )
                })}
            </DialogActions>
        </Dialog>
    );
}

export default StandardModal